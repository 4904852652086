import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="message-banner"
export default class extends Controller {
  static targets = ["bannerContent", "secondContent", "wrapper"];

  addMarquee() {
    this.wrapperTarget.classList.add("marquee-banner");
    this.element.classList.remove("justify-center");
    this.bannerContentTarget.classList.add("marquee-banner__content");
    this.secondContentTarget.classList.add("marquee-banner__content");
    this.secondContentTarget.classList.remove("hidden");
  }

  connect() {
    this.onResize();
  }

  disconnect() {
    this.removeMarquee();
  }

  onResize() {
    this.addMarquee();
    if (this.bannerContentTarget.offsetWidth < window.innerWidth) {
      this.removeMarquee();
    }
  }

  removeMarquee() {
    this.wrapperTarget?.classList.remove("marquee-banner");
    this.element.classList.add("justify-center");
    this.bannerContentTarget?.classList.remove("marquee-banner__content");
    this.secondContentTarget?.classList.remove("marquee-banner__content");
    this.secondContentTarget?.classList.add("hidden");
  }
}
