import { Controller } from "@hotwired/stimulus";

import Swiper from "swiper";
import "swiper/css";
import { Autoplay, EffectFade, Navigation, Pagination, Parallax, Thumbs } from "swiper/modules";

import { VidstackPlayer } from "vidstack/global/player";
import "vidstack/player/styles/base.css";
import "vidstack/player/styles/plyr/theme.css";

import { SWIPER_CONFIGS } from "../constants/swiper";

// Connects to data-controller="swiper"
export default class extends Controller {
  static targets = [
    "swiper",
    "thumbsSwiper",
    "pagination",
    "prev",
    "next",
    "paginationThumbnail",
    "prevThumbnail",
    "nextThumbnail",
    "slides",
    "videos",
  ];

  config() {
    return SWIPER_CONFIGS[this.element.dataset.config] || {};
  }

  connect() {
    if (!this.element.dataset.mobileOnly) {
      this.init();
      return;
    }

    this.mql = window.matchMedia("(max-width: 768px)");

    if (this.mql.matches) {
      this.init();
    }

    this.mql.addEventListener("change", this.onMobileChange);
  }

  disconnect() {
    this.mql?.removeEventListener("change", this.onMobileChange);
    this.mql = undefined;

    this.slider?.destroy();
    this.slider = undefined;

    this.disposeMediaQueries?.();
    this.disposeMediaQueries = undefined;

    this.element.classList.remove("fade-in--manual");
    this.element.classList.remove("swiper-activated");
    this.element.classList.remove("swiper-pagination-enabled");
  }

  onMobileChange = ({ matches }) => {
    if (!matches) {
      this.disconnect();
    }
    else if (!this.slider) {
      this.init();
    }
  };

  init() {
    if (this.slidesTargets.length == 0) return;

    if (!this.element.classList.contains("swiper-activated")) {
      this.element.classList.add("swiper-activated");
    }

    // Set events and such
    this.slider = new Swiper(this.swiperTarget, {
      autoplay: this.element.dataset.autoplay
        ? {
          delay: this.element.dataset.autoplay,
        }
        : undefined,
      // centeredSlides: true,
      centerInsufficientSlides: !!this.element.dataset.centerSlides,
      clickable: true,
      draggable: true,
      effect: this.element.dataset.effect || undefined,
      grabCursor: true,
      modules: [
        this.hasNextTarget && Navigation,
        this.hasPaginationTarget && Pagination,
        Autoplay,
        Parallax,
        this.hasThumbsSwiperTarget && Thumbs,
        this.element.dataset.effect === "fade" && EffectFade,
      ].filter((x) => x),
      navigation: this.hasNextTarget
        ? {
          enabled: true,
          nextEl: this.nextTarget,
          prevEl: this.prevTarget,
        }
        : undefined,

      on: {
        afterInit: () => {
          if (!this.element.classList.contains("fade-in--manual")) {
            this.element.classList.add("fade-in--manual");
          }
        },
      },
      pagination: this.hasPaginationTarget
        ? {
          clickable: true,
          el: this.paginationTarget,
        }
        : undefined,
      parallax: true,
      slidesPerView: +this.element.dataset.slidesPerView || "auto",
      spaceBetween: this.element.dataset.spaceBetween ?? 0,

      speed: 600,
      thumbs: this.hasThumbsSwiperTarget
        ? {
          swiper: this.thumbsSwiper,
        }
        : undefined,

      ...this.config(),
    });

    this.slider.on("activeIndexChange", () => {
      const idx = this.slider.activeIndex;
      this.playVideoAtIndex(idx);
      if (this.hasThumbsSwiperTarget) {
        this.thumbsSwiperTarget?.querySelectorAll(".swiper-slide")?.[idx]?.querySelector("a")?.click();
      }
    });

    this.addPaginationVisibleMediaQueries();
  }

  addPaginationVisibleMediaQueries() {
    if (this.shouldDisplayPagination(+this.element.dataset.slidesPerView || 1)) {
      if (!this.element.classList.contains("swiper-pagination-enabled")) {
        this.element.classList.add("swiper-pagination-enabled");
      }
    }

    const config = this.config();
    if (!config.breakpoints) return;

    const mediaQueryDestroyFns = [];
    Object.entries(config.breakpoints).forEach((breakpoint, i, breakpoints) => {
      const nextBreakpoint = breakpoints[i + 1];

      const query = [`(min-width: ${breakpoint[0]}px)`, nextBreakpoint && `(max-width: ${+nextBreakpoint[0] - 1}px)`]
        .filter((x) => x)
        .join(" and ");

      const handler = ({ matches }) => {
        if (!matches) return;

        if (!this.shouldDisplayPagination(+(breakpoint[1].slidesPerView) || 1)) {
          this.element.classList.remove("swiper-pagination-enabled");
        }
        else if (!this.element.classList.contains("swiper-pagination-enabled")) {
          this.element.classList.add("swiper-pagination-enabled");
        }
      };

      const mql = window.matchMedia(query);
      if (mql.matches) {
        handler({ matches: true });
      }

      mql.addEventListener("change", handler);

      mediaQueryDestroyFns.push(() => mql.removeEventListener("change", handler));
    });

    this.disposeMediaQueries = () => mediaQueryDestroyFns.forEach((handler) => handler());
  }

  shouldDisplayPagination(slidesPerView) {
    return this.slidesTargets.length > slidesPerView;
  }

  playVideoAtIndex(idx) {
    for (let i = 0; i < this.slidesTargets.length; ++i) {
      if (i !== idx) this.playerAtIndex(i)?.pause();
    }

    this.playerAtIndex(idx)?.play();
  }

  playerAtIndex(idx) {
    return this.slidesTargets[idx]?.querySelector("media-player");
  }

  thumbsSwiperTargetConnected(target) {
    if (this.slidesTargets.length == 0) return;

    this.thumbsSwiper = new Swiper(target, {
      freeMode: true,
      modules: [this.hasNextThumbnailTarget && Navigation, this.hasPaginationThumbnailTarget && Pagination].filter(
        (x) => x,
      ),
      navigation: this.hasNextThumbnailTarget
        ? {
          enabled: true,
          nextEl: this.nextThumbnailTarget,
          prevEl: this.prevThumbnailTarget,
        }
        : undefined,
      pagination: this.hasPaginationThumbnailTarget
        ? {
          clickable: true,
          dynamicBullets: !!this.element.dataset.dynamic,
          dynamicMainBullets: 5,
          el: this.paginationThumbnailTarget,
        }
        : undefined,
      slidesPerView: 4,
      spaceBetween: 24,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
    });
  }

  thumbsSwiperTargetDisconnected() {
    this.thumbsSwiper?.destroy();
    this.thumbsSwiper = undefined;
  }

  videosTargetConnected(target) {
    const promise = VidstackPlayer.create({
      aspectRatio: "5/6",
      controls: false,
      load: "eager",
      playsinline: true,
      src: target.dataset.video,
      target: target,
    });

    (this.playerPromises || (this.playerPromises = new Map())).set(target, promise);
  }

  videosTargetDisconnected(target) {
    this.playerPromises?.get(target)?.then((player) => player.destroy());
    this.playerPromises?.delete(target);
  }
}
