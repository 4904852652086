import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accordeon-group"
export default class extends Controller {
  static targets = ["accordeons"];

  connect() {}

  disconnect() {}

  onCheck(event) {
    this.accordeonsTargets.forEach((accordeon) => {
      if (event.currentTarget.id != accordeon.id) {
        accordeon.checked = false;
        const openedChild = accordeon.parentElement.querySelector('[data-accordeon-group-target="accordeons"]:checked');
        if (openedChild) {
          openedChild.checked = false; // closes subgroup accordeon
        }
      }
    });
  }
}
