export const SWIPER_CONFIGS = {
  relatedProducts: {
    breakpoints: {
      640: {
        slidesPerView: "auto",
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
      1280: {
        init: false,
        slidesPerView: 4,
      },
    },
  },
};
