import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ["buttons", "tabs", "tabsWrapper"];

  connect() {
    this.currentIndex = 0;
    this.buttonsTargets[0].classList.add("current-tab");
    this.tabsTargets[0].classList.add("current-tab");
    gsap.set(this.tabsTargets[0], { opacity: 1, zIndex: 1 });
    console.log(this.tabsTargets[this.currentIndex].offsetHeight);
    gsap.set(this.tabsWrapperTarget, { height: this.tabsTargets[this.currentIndex].offsetHeight });
  }

  disconnect() { }

  onClick(event) {
    const newIndex = event.currentTarget.dataset.tabIndex;
    if (newIndex != this.currentIndex) {
      if (this.currentIndex != null) {
        this.buttonsTargets[this.currentIndex].classList.remove("current-tab");
        this.tabsTargets[this.currentIndex].classList.remove("current-tab");
        gsap.to(this.tabsTargets[this.currentIndex], { duration: 0.35, opacity: 0, zIndex: -1 });
      }

      event.currentTarget.classList.add("current-tab");
      this.tabsTargets[newIndex].classList.add("current-tab");

      gsap.to(this.tabsTargets[newIndex], { delay: 0.25, duration: 0.35, opacity: 1, zIndex: 1 });
      gsap.to(this.tabsWrapperTarget, { duration: 0.20, height: this.tabsTargets[newIndex].offsetHeight });
      this.currentIndex = newIndex;

      if (newIndex == 0) {
        this.tabsWrapperTarget.classList.add("rounded-tr-md")
        this.tabsWrapperTarget.classList.remove("rounded-tl-md")
      } else {
        this.tabsWrapperTarget.classList.remove("rounded-tr-md")
        this.tabsWrapperTarget.classList.add("rounded-tl-md")
      }
    }
  }

  onResize() {
    gsap.to(this.tabsWrapperTarget, { duration: 0.35, height: this.tabsTargets[this.currentIndex].offsetHeight });
  }
}
