import { Controller } from "@hotwired/stimulus";
import { debounce } from "remeda";

// Connects to data-controller="quantity"
export default class extends Controller {
  static targets = ["quantityInput", "decrementButton", "incrementButton"];

  static values = {
    submit: String,
  };

  connect() {
    this.adjust(0, { updateValue: false, canSubmit: false });
  }

  onInput() {
    this.adjust(0, { updateValue: false });
  }

  increment() {
    this.adjust(1);
  }

  decrement() {
    this.adjust(-1);
  }

  adjust(n, { updateValue = true, canSubmit = true } = {}) {
    let input = this.quantityInputTarget;
    if (input == null) return;

    let minValue = (input.min != null && input.min !== '') ? +(input.min) : 0;
    let maxValue = (input.max != null && input.max !== '') ? +(input.max) : Infinity;
    let value = Math.min(maxValue, Math.max(minValue, +(input.value) + n));
    if (updateValue) input.value = value;

    if (this.decrementButtonTarget) this.decrementButtonTarget.disabled = (value <= minValue);
    if (this.incrementButtonTarget) this.incrementButtonTarget.disabled = (value >= maxValue);

    if (canSubmit && this.submitValue === "debounce") {
      this.debouncedSubmit.call();
    }
  }

  debouncedSubmit = debounce(
    () => {
      this.element?.closest('form')?.requestSubmit();
    },
    { timing: "trailing", waitMs: 800 },
  )
}
