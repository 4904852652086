import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="lockscroll"
export default class extends Controller {
  handleScroll = () => {
    this.stickyTarget.style.transform = `translateY(${
      (this.contentTarget.getBoundingClientRect().top / (window.innerHeight * 4)) * this.stickyTarget.clientHeight
    }px)`;
  };

  static targets = ["content", "sticky"];

  connect() {
    window.addEventListener("scroll", this.handleScroll);
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
