import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
// Connects to data-controller="simple-filters"
export default class extends Controller {
  static targets = ["buttons", "buttonsWrapper", "tabs", "tabsWrapper"];

  connect() {
    let currentEl = null;
    if (window.location.hash != "") {
      this.currentSlug = window.location.hash.replace("#", "");
      currentEl = this.tabsWrapperTarget.querySelector('[data-simple-filters-index="' + this.currentSlug + '"]');
      this.buttonsWrapperTarget
        .querySelector('[data-simple-filters-index="' + this.currentSlug + '"]')
        .classList.add("current-tab");
    } else {
      currentEl = this.tabsTargets[0];
      this.buttonsTargets[0].classList.add("current-tab");
      this.currentSlug = this.tabsTargets[0].dataset.simpleFiltersIndex;
    }
    currentEl.classList.add("fade-in-childs--manual", "fade-in--manual");
    gsap.set(currentEl, { opacity: 1, zIndex: 1, position: "relative" });
    // gsap.set(this.tabsWrapperTarget, { height: currentEl.offsetHeight });
  }

  disconnect() { }

  onClick(event) {
    const newSlug = event.currentTarget.dataset.simpleFiltersIndex;

    if (newSlug != this.currentSlug) {
      const currentEl = this.tabsWrapperTarget.querySelector(
        '[data-simple-filters-index="' + this.currentSlug + '"]',
      );
      if (currentEl) {
        gsap.to(currentEl, { duration: 0.35, opacity: 0, zIndex: -1, position: "absolute" });
        this.buttonsWrapperTarget
          .querySelector('[data-simple-filters-index="' + this.currentSlug + '"]')
          .classList.remove("current-tab");
        currentEl.classList.remove("fade-in-childs--manual", "fade-in--manual");
      }

      if (history.pushState) {
        history.pushState(null, null, "#" + newSlug);
      }

      this.buttonsWrapperTarget
        .querySelector('[data-simple-filters-index="' + newSlug + '"]')
        .classList.add("current-tab");
      const newEl = this.tabsWrapperTarget.querySelector('[data-simple-filters-index="' + newSlug + '"]');
      gsap.delayedCall(0.1, () => {
        newEl.classList.add("fade-in-childs--manual", "fade-in--manual");
      });
      gsap.to(newEl, { delay: 0.25, duration: 0.35, opacity: 1, zIndex: 1, position: "relative" });
      this.currentSlug = newSlug;
      console.log(newEl.offsetHeight)
      gsap.to(this.tabsWrapperTarget, { duration: 0.35, height: newEl.offsetHeight }).then(() => {
        gsap.set(this.tabsWrapperTarget, { height: null });
        // gsap.to(this.tabsWrapperTarget, { duration: 0.35, height: newEl.offsetHeight }).then(() => {
        //   gsap.set(this.tabsWrapperTarget, { height: null });
        // });
      });
    }
  }
}
