import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="price-calculator"
export default class extends Controller {
  connect() {
    const small_bars_milk = document.getElementById("choco_finance_form_small_bars_milk");
    const small_bars_black = document.getElementById("choco_finance_form_small_bars_black");
    this.choco_finance_submit = document.getElementById("choco_finance_submit");
    this.choco_finance_errors = document.getElementById("choco_finance_errors");

    small_bars_milk.addEventListener("input", () => this.updateTotal(small_bars_milk, small_bars_black, this.element));
    small_bars_black.addEventListener("input", () => this.updateTotal(small_bars_milk, small_bars_black, this.element));
  }

  disconnect() {}

  updateTotal(small_bars_milk, small_bars_black, price_calculator) {
    const quantity_small_bars_milk =
      small_bars_milk.value != "" && small_bars_milk.value.match(new RegExp("^[0-9]*$"))
        ? parseInt(small_bars_milk.value)
        : 0;
    const quantity_small_bars_black =
      small_bars_black.value != "" && small_bars_black.value.match(new RegExp("^[0-9]*$"))
        ? parseInt(small_bars_black.value)
        : 0;

    const total_boxes = quantity_small_bars_milk + quantity_small_bars_black;

    this.choco_finance_errors.innerHTML = total_boxes < 10 ? choco_finance_errors.dataset.error : "";

    //choco_finance_submit.disabled = total_boxes < 10;
    this.element.innerHTML = `${total_boxes * 98}$`;
  }
}
