export function addClickListener(el, sel, cb, options) {
  function listener(event) {
    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) return;
    cb(event);
  }

  return addListener(el, 'click', sel, listener, options);
}

export function addListener(el, type, sel, cb, options) {
  function listener(event) {
    let el = event.target.closest(sel);
    if (el) cb(event);
  }

  el.addEventListener(type, listener, options);

  return function() {
    el.removeEventListener(type, listener, options);
  };
}
