import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(MotionPathPlugin);

import Swiper from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade, Navigation } from "swiper/modules";

// Connects to data-controller="history"
export default class extends Controller {
  static targets = [
    "swiper",
    "pagination",
    "prev",
    "next",
    "yearsWrapper",
    "contentsWrapper",
    "paginationWrapper",
    "paginationScroller",
    "paginationItems",
    "paginationLine",
    "paginationMarker",
  ];

  connect() {
    this.isSwiping = false;
    this.slider = new Swiper(this.swiperTarget, {
      clickable: true,
      draggable: true,

      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      grabCursor: true,
      modules: [Navigation, EffectFade],
      navigation: {
        enabled: true,
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
      on: {
        activeIndexChange: (swiper) => {
          this.element.querySelector(".history__year.active").classList.remove("active");
          this.element.querySelector(".history__content.active").classList.remove("active");
          const previousPage = this.element.querySelector(".history__pagination__item.active");
          previousPage.classList.remove("active");

          const activeYear = this.element.querySelector(`.history__year[data-slide-index="${swiper.activeIndex}"]`);

          const activeContent = this.element.querySelector(
            `.history__content[data-slide-index="${swiper.activeIndex}"]`,
          );

          const activePage = this.element.querySelector(
            `.history__pagination__item[data-slide-index="${swiper.activeIndex}"]`,
          );

          gsap.to(this.yearsWrapperTarget, {
            delay: swiper.activeIndex > swiper.previousIndex ? 0 : 0.4,
            duration: 0.8,
            width: activeYear.offsetWidth < 100 ? 100 : activeYear.offsetWidth,
          });
          activeYear.classList.add("active");
          activeContent.classList.add("active");
          activePage.classList.add("active");

          gsap.to(this.paginationScrollerTarget, { duration: 1, scrollTo: { offsetX: 16, x: activePage } });

          const startX =
            previousPage.offsetLeft + previousPage.offsetWidth / 2 - this.paginationMarkerTarget.offsetWidth / 2;
          const finalX =
            activePage.offsetLeft + activePage.offsetWidth / 2 - this.paginationMarkerTarget.offsetWidth / 2;
          const middleX = startX + (finalX - startX) / 2;
          gsap.to(this.paginationMarkerTarget, {
            delay: 0,
            duration: 0.5,
            ease: "power1.out",
            motionPath: {
              path: [
                { x: startX, y: 0 },
                {
                  x: middleX,
                  y: -25,
                },
                { x: finalX, y: 0 },
              ],
            },
          });
        },
        afterInit: (swiper) => {
          const activeYear = this.element.querySelector(`.history__year[data-slide-index="${swiper.activeIndex}"]`);
          activeYear.classList.add("active");

          this.yearsWrapperTarget.style.width = activeYear.offsetWidth < 100 ? "100px" : activeYear.offsetWidth + "px";

          this.element
            .querySelector(`.history__content[data-slide-index="${swiper.activeIndex}"]`)
            .classList.add("active");
          this.element
            .querySelector(`.history__pagination__item[data-slide-index="${swiper.activeIndex}"]`)
            .classList.add("active");

          this.element.classList.add("fade-in--manual");


          const activePage = this.element.querySelector(".history__pagination__item.active");
          gsap.to(this.paginationMarkerTarget, {
            duration: 0,
            x: activePage.offsetLeft + activePage.offsetWidth / 2 - this.paginationMarkerTarget.offsetWidth / 2,
          });
        },
        beforeInit: () => {
          this.onResize();
        },
        resize: (swiper) => {
          this.onResize();
        },
      },
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 1200,
    });
  }

  disconnect() {
    this.slider.destroy();
    this.element.classList.remove("fade-in--manual");
  }

  onResize() {
    this.setLargestDimension(this.contentsWrapperTarget);
    this.paginationLineTarget.style.width = null;
    this.paginationLineTarget.style.width = this.paginationWrapperTarget.scrollWidth + "px";

    const activePage = this.element.querySelector(".history__pagination__item.active");
    gsap.to(this.paginationMarkerTarget, {
      x: activePage.offsetLeft + activePage.offsetWidth / 2 - this.paginationMarkerTarget.offsetWidth / 2,
    });
  }

  onPaginationClick(event) {
    if (!this.isSwiping) {
      this.slideCloser(event.currentTarget.dataset.slideIndex)
    }
  }

  slideCloser(finalIndex) {
    this.isSwiping = true;
    const currentIndex = this.slider.activeIndex;
    if (currentIndex < finalIndex) {
      this.slider.slideNext();
    } else {
      this.slider.slidePrev();
    }

    gsap.delayedCall(0.4, () => {
      if (this.slider.activeIndex != finalIndex) {
        this.slideCloser(finalIndex)
      } else {
        this.isSwiping = false;
      }
    })
  }
  setLargestDimension(element) {
    let largestWidth = 0;
    let largestHeight = 0;
    Array.from(element.children).forEach((child) => {
      if (child.offsetWidth > largestWidth) {
        largestWidth = child.offsetWidth;
      }
      if (child.offsetHeight > largestHeight) {
        largestHeight = child.offsetHeight;
      }
    });
    element.style.width = largestWidth + "px";
    element.style.height = largestHeight + "px";
  }
}
