export const buildInfoBox = (place, placeData, translations, address, isMobile) => {
  let opening_hours = "";
  if (placeData.opening_hours) {
    opening_hours = `<div class="flex flex-col gap-4">
        <div class="font-tradegothic font-bold text-red text-sm uppercase">
        ${translations.opening}
        </div>
        <div class="font-tradegothic text-brown text-xs flex flex-col normalcase">
          ${placeData.opening_hours.weekday_text.map((text) => `<div>${text}</div>`).join("")}
        </div>
      </div>`;
  }
  return `<div class="googlemap-infobox ${isMobile ? "" : "py-10 px-6 max-md:hidden"} landscape:w-[468px] bg-white text-left">
    <div class="font-garagegothic font-bold text-red text-md uppercase">
    ${place.name_fr}
    </div>

    <div class="grid grid-cols-1 landscape:grid-cols-2 gap-6 mt-6">
      ${opening_hours}
      <div class="flex flex-col gap-4">
        <div class="font-tradegothic font-bold text-red text-sm uppercase">
        ${translations.address}
        </div>
        <div class="font-tradegothic text-brown text-xs flex flex-col normalcase gap-6">
          <a class="transition-opacity hover:opacity-50" href="${placeData.url}" target="_blank">${address}</a>
        
          <a class="transition-opacity hover:opacity-50" href="tel:${placeData.formatted_phone_number}">${placeData.formatted_phone_number}</a>
        </div>
      </div>
    </div>
  </div>`;
};
