import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";

// Connects to data-controller="cart-count"
export default class extends Controller {
  connect() {
    const currentCount = this.element.dataset.count;
    const oldCount = localStorage.getItem("cart-count");

    if (currentCount > 0) {
      if (oldCount == null || oldCount == 0) {
        this.element.classList.add("pop-in")
        gsap.delayedCall(0.1, () => this.element.classList.add("active"))
      } else {
        this.element.classList.add("active")
      }
    }
    localStorage.setItem("cart-count", currentCount);
  }
  disconnect() {

  }
}
