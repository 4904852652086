import { Controller } from "@hotwired/stimulus";
import { wait } from "../utils/timing";

// Connects to data-controller="form"
export default class extends Controller {
  static targets = ["message"];
  connect() {
    window.MAIN_FORM = {
      complete: this.complete,
    };
  }

  complete = async () => {
    const messageElement = this.messageTarget;

    if (!(messageElement instanceof HTMLElement)) {
      return;
    }

    this.element.classList.add("--closing");

    await wait(300);

    this.element.style.maxHeight = `${this.element.scrollHeight}px`;

    await wait(0);

    this.element.style.maxHeight = `${messageElement.scrollHeight}px`;

    await wait(300);

    this.element.classList.add("--closed");

    this.element.style.maxHeight = `none`;
    this.element.classList.add("--opening");
  };
}
