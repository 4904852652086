import { Controller } from "@hotwired/stimulus";
import jsonp from "../utils/jsonp";

// Connects to data-controller="newsletter"
export default class extends Controller {
  onSubmit(event) {
    let formData = new FormData(event.target);
    let params = new URLSearchParams(formData);
    let url = event.target.action.replace('/post?', '/post-json?') + '&' + params;

    event.target.setAttribute('data-submitting', 'true');

    jsonp(url, { param: 'c', semi: true }, function(err, data) {
      if (data && data.result === 'success') {
        event.target.setAttribute('data-submitting', 'success');
      }
      else {
        event.target.setAttribute('data-submitting', 'error');
      }
    });

    event.preventDefault();
  }
}
