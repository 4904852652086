import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";

// Connects to data-controller="copy-to-clipboard"
export default class extends Controller {
  static targets = [
  ];

  connect() {

  }

  disconnect() {

  }

  onClick() {
    navigator.clipboard.writeText(this.element.dataset.content);
    this.showTooltip();
    gsap.delayedCall(2, this.hideTooltip.bind(this))
  }

  showTooltip() {
    this.element.classList.add("active");
  }

  hideTooltip() {
    this.element.classList.remove("active");
  }
}
