import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="fade-in-childs"
export default class extends Controller {
  static targets = [];

  connect() {
    this.previousIntersectionY = 0;
    this.repeat = this.element.dataset.repeat ?? true;
    this.manual = this.element.dataset.manual ?? false;
    this.threshold = this.element.dataset.threshold ?? 0;

    this.element.style.setProperty(
      "--fade-in-childs-duration",
      this.element.dataset.duration ? this.element.dataset.duration + "s" : "1.1s",
    );
    this.element.style.setProperty(
      "--fade-in-childs-start-delay",
      this.element.dataset.delay ? this.element.dataset.delay + "s" : "0.2s",
    );
    this.element.style.setProperty(
      "--fade-in-childs-childs-delay",
      this.element.dataset.childsDelay ? this.element.dataset.childsDelay + "s" : "0.1s",
    );
    this.element.style.setProperty("--fade-in-childs-y", this.element.dataset.y ?? "2rem");
    this.element.style.setProperty("--fade-in-childs-x", this.element.dataset.x ?? "0");
    this.element.style.setProperty("--fade-in-childs-mobile-y", this.element.dataset.mobileY ?? this.element.dataset.y ?? "2rem");

    Array.from(this.element.children).forEach((child, index) => {
      child.style.setProperty("--fade-in-childs-index", index);
    });

    if (!this.manual) {
      this.observer = new IntersectionObserver(
        (entries, _observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.element.classList.add("fade-in-childs--visible");
            } else if (entry.boundingClientRect.y > this.previousIntersectionY) {
              // Scrolling up
              if (this.repeat && !entry.isIntersecting) {
                // Scrolling up leave
                this.element.classList.remove("fade-in-childs--visible");
              }
            }
            this.previousIntersectionY = entry.boundingClientRect.y;
          });
        },
        {
          threshold: this.threshold,
        },
      );
      this.observer.observe(this.element);
    }
  }

  disconnect() {
    this.element.classList.remove("fade-in-childs--visible");
    this.element.classList.remove("fade-in-childs--manual");

    if (this.observer) {
      this.observer.disconnect();
    }
  }
}

/* Fade-in-childs
  Fade in animation on all the children when the parent element is visible in the viewport

  Data attributes
  - repeat: if true the animation will repeat when the user scrolls back up and then scrolls back down (default: false)
  - manual: if true the animation will not trigger on scroll. Useful for popups and menus. Trigger by adding the class .fade-in--manual to any ancestor (default: false)
  - duration: duration of the animation in seconds (default: 1.1)]
  - delay: delay after the trigger before the start of the animation in seconds (default: 0.2)
  - childs-delay: offset between each children, total delay is (delay + (childs-delay * index of child)) (default: 0.1)
  - y: inital position on the y axis (default: 2rem, final value: 0)
  - x: inital position on the x axis, useful for a fade-in from the side (default: 0, final value: 0)
  - threshold: threshold value for the IntersectionObserver (default: 0)
*/
