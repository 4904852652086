import { Controller } from "@hotwired/stimulus";

function isScriptAdded(src) {
  return Boolean(document.querySelector('script[src="' + src + '"]'));
}

function loadScript(src, onLoadCallback, onLoadErrorCallback) {
  if (!src) return;

  if (isScriptAdded(src)) {
    onLoadCallback?.();

    return;
  }

  const js = document.createElement("script");
  js.setAttribute("async", "");
  js.src = src;

  js.onload = () => {
    onLoadCallback?.();
  };

  js.onerror = () => {
    const js_ = document.querySelector('script[src="' + src + '"]');
    js_.parentNode.removeChild(js2);
    onLoadErrorCallback?.();
  };

  document.head.appendChild(js);
}

var instagramConfig = {
  container: "#curator-feed-default-feed-layout",
  debug: 0,
  embedSource: "",
  feed: {
    apiEndpoint: "https://api.curator.io",
    id: "28f36c59-cfb4-4c18-b166-b3fed6722212",
    limit: 9,
    params: {},
    postsPerPage: 9,
  },
  filter: {
    default: "all",
    limitPostNumber: 0,
    limitPosts: false,
    period: "",
    showAll: false,
    showNetworks: false,
    showSources: false,
    template: "filter",
  },
  forceHttps: false,
  hidePoweredBy: false,
  lang: "en",
  popup: { autoPlayVideos: false, deepLink: false, template: "popup", templateWrapper: "popup-wrapper" },
  post: {
    autoPlayVideos: false,
    clickAction: "goto-source",
    clickReadMoreAction: "goto-source",
    imageHeight: "100%",
    maxLines: 0,
    minWidth: 0,
    showComments: false,
    showLikes: false,
    showShare: false,
    showTitles: true,
    template: "post-grid",
  },
  theme: "sydney",
  type: "Grid",
  widget: {
    animate: false,
    autoLoadNew: !1,
    continuousScroll: !1,
    continuousScrollOffset: 50,
    gridMobile: false,
    horizontalSpacing: 10,
    lazyLoadType: "none",
    loadMoreRows: 0,
    rows: 3,
    showLoadMore: !1,
    template: "widget-grid",
    verticalSpacing: 10,
  },
};
var instagramColours = {
  bgColor: "#ffffff",
  borderColor: "#cccccc",
  dateColor: "#000000",
  footerColor: "#ffffff",
  iconColor: "#222222",
  linkColor: "#999999",
  tabIndexColor: "#cccccc",
  textColor: "#222222",
  widgetBgColor: "transparent",
};
var instagramStyles = {};

var tikTokconfig = {
  debug: 0,
  embedSource: "",
  feed: {
    apiEndpoint: "https://api.curator.io",
    id: "e48f4d77-ae1c-4688-b581-dd055f348c9e",
    limit: 25,
    params: {},
    postsPerPage: 12,
  },
  filter: {
    default: "all",
    limitPostNumber: 0,
    limitPosts: false,
    period: "",
    showAll: false,
    showNetworks: false,
    showSources: false,
    template: "filter",
  },
  forceHttps: false,
  hidePoweredBy: false,
  lang: "en",
  popup: { autoPlayVideos: false, deepLink: false, template: "popup", templateWrapper: "popup-wrapper" },
  post: {
    autoPlayVideos: false,
    clickAction: "open-popup",
    clickReadMoreAction: "open-popup",
    matchHeights: false,
    maxHeight: 0,
    maxLines: 0,
    minWidth: 250,
    showComments: false,
    showLikes: false,
    showShare: true,
    showTitles: true,
  },
  theme: "sydney",
  type: "Grid",
  widget: {
    autoLoad: true,
    autoLoadNew: false,
    autoPlay: true,
    controlsOver: false,
    controlsShowOnHover: false,
    duration: 700,
    easing: "ease-in-out",
    gridMobile: false,
    horizontalSpacing: 10,
    infinite: false,
    lazyLoad: false,
    lazyLoadType: "none",
    moveAmount: 0,
    panesVisible: -1,
    progressiveLoad: false,
    rows: 1,
    speed: 5000,
    template: "widget-carousel",
    useCss: true,
    verticalSpacing: 10,
  },
};
var tikTokColours = {
  bgColor: "#ffffff",
  borderColor: "#cccccc",
  dateColor: "#000000",
  footerColor: "#ffffff",
  iconColor: "#222222",
  linkColor: "#999999",
  tabIndexColor: "#cccccc",
  textColor: "#222222",
  widgetBgColor: "transparent",
};
var tikTokStyles = {};

const buildInstagram = (element) => {
  window.Curator.loadWidget({ ...instagramConfig, container: element }, instagramColours, instagramStyles);
};

const buildTiktok = (element) => {
  window.Curator.loadWidget({ ...tikTokconfig, container: element }, tikTokColours, tikTokStyles);
};

// Connects to data-controller="curator"
export default class extends Controller {
  connect() {
    loadScript("https://cdn.curator.io/5.0/curator.embed.js", () => {
      if (this.element.dataset.curatorType === "instagram") {
        buildInstagram(this.element);
      } else {
        buildTiktok(this.element);
      }
    });
  }
}
