var count = 0;

function noop() {}

/**
 * JSONP handler
 * Based on jsonp npm package, with workaround for broken mailchimp response
 *
 * Options:
 *  - param {String} qs parameter (`callback`)
 *  - prefix {String} qs parameter (`__jp`)
 *  - name {String} qs parameter (`prefix` + incr)
 *  - semi {Boolean} when true, includes semicolon prefix in callback param (mailchimp workaround)
 *  - timeout {Number} how long after a timeout error is emitted (`60000`)
 *
 * @param {String} url
 * @param {Object|Function} optional options / callback
 * @param {Function} optional callback
 */

export default function jsonp(url, opts, fn) {
  if (typeof opts === 'function') {
    fn = opts;
    opts = {};
  }
  else if (!opts) opts = {};

  var prefix = opts.prefix || '__jpc';

  // use the callback name that was passed if one was provided.
  // otherwise generate a unique name by incrementing our counter.
  var id = opts.name || (prefix + (count++));

  var param = opts.param || 'callback';
  var timeout = null != opts.timeout ? opts.timeout : 60000;
  var target = document.getElementsByTagName('script')[0] || document.head;
  var script;
  var timer;

  if (timeout) {
    timer = setTimeout(function(){
      var f = fn;
      cleanup();
      if (f) f(new Error('Timeout'));
    }, timeout);
  }

  function cleanup(){
    if (timer) clearTimeout(timer);
    window[id] = noop;
    if (script && script.parentNode) script.parentNode.removeChild(script);
    script = fn = null;
  }

  window[id] = function(data){
    var f = fn;
    cleanup();
    if (f) f(null, data);
  };

  url += (url.indexOf('?') === -1 ? '?' : '&') + encodeURIComponent(param) + '=' + encodeURIComponent(opts.semi ? ';' + id : id);

  script = document.createElement('script');
  script.src = url;
  target.parentNode.insertBefore(script, target);

  return cleanup;
}
