const extractAttributes = (element, ...attrs) => {
  return attrs.reduce((prev, current) => ({ ...prev, [current]: element.getAttribute(current) }), {});
};

/**
 * Based on https://github.com/hotwired/turbo/issues/792
 */
Turbo.StreamActions.push_state = function () {
  const url = this.getAttribute("url");
  const state = { turbo_stream_history: true };
  Turbo.cache.exemptPageFromPreview();
  history.replaceState(state, "", window.location.href);
  history.pushState(state, "", url);
};

Turbo.StreamActions.replace_state = function () {
  const url = this.getAttribute("url");
  const state = { turbo_stream_history: true };
  Turbo.cache.exemptPageFromPreview();
  history.replaceState(state, "", url);
};

Turbo.StreamActions.set_attribute = function () {
  const { attribute, id, value } = extractAttributes(this, "id", "attribute", "value");
  document.getElementById(id).setAttribute(attribute, value);
};

Turbo.StreamActions.add_body_class = function () {
  const klass = this.getAttribute("klass");

  document.body.classList.add(klass);
};

Turbo.StreamActions.complete_main_form = function () {
  window.MAIN_FORM?.complete();
};

window.addEventListener("popstate", (event) => {
  if (event.state && event.state.turbo_stream_history) {
    Turbo.visit(window.location.href, { action: "replace" });
  }
});
