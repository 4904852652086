import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
// Connects to data-controller="dropdown-filters"
export default class extends Controller {
  static targets = ["dropdown", "items", "itemsWrapper"];

  connect() {

    const currentEl = this.itemsTargets[0]
    this.currentIndex = 0;
    gsap.set(currentEl, { opacity: 1, zIndex: 1 });
    gsap.set(this.itemsWrapperTarget, { height: currentEl.offsetHeight });
  }

  disconnect() { }

  onSelect(event) {
    const newIndex = event.currentTarget.value

    if (newIndex != this.currentIndex) {

      const currentEl = this.itemsTargets[this.currentIndex];
      if (currentEl) {
        gsap.to(currentEl, { duration: 0.35, opacity: 0, zIndex: -1 })

      }

      const newEl = this.itemsTargets[newIndex];

      gsap.to(newEl, { delay: 0.25, duration: 0.35, opacity: 1, zIndex: 1 });
      this.currentIndex = newIndex;
      gsap.to(this.itemsWrapperTarget, { duration: 0.35, height: newEl.offsetHeight });

    }
  }
  onChange(event) {

  }
  // onCheck(event) {
  //   const previousAccordeons = event.currentTarget.parentElement.parentElement.querySelectorAll('[data-accordeon-group-target="accordeons"]:checked');
  //   let previousAccordeon = null
  //   previousAccordeons.forEach(accordeon => {
  //     if (event.currentTarget.id != accordeon.id) {
  //       previousAccordeon = accordeon
  //     }
  //   });
  //   let accordeonContentHeight = event.currentTarget.parentElement.querySelector(".accordeon-content").offsetHeight;
  //   if (previousAccordeon) {
  //     const previousAccordeonHeight = previousAccordeon.parentElement.querySelector(".accordeon-content").offsetHeight;
  //     accordeonContentHeight -= previousAccordeonHeight;
  //   }

  //   if (event.currentTarget.checked) {
  //     gsap.to(this.itemsWrapperTarget, { duration: 0.35, height: this.itemsWrapperTarget.querySelector('[data-simple-filters-index="' + this.currentSlug + '"]').offsetHeight + accordeonContentHeight });
  //   } else {
  //     gsap.to(this.itemsWrapperTarget, { delay: 0.1, duration: 0.35, height: this.itemsWrapperTarget.querySelector('[data-simple-filters-index="' + this.currentSlug + '"]').offsetHeight - accordeonContentHeight });
  //   }

  // }
  onResize() {
    // gsap.to(this.itemsWrapperTarget, { duration: 0.35, height: this.itemsWrapperTarget.querySelector('[data-simple-filters-index="' + this.currentSlug + '"]').offsetHeight });
  }
}
