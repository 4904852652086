import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// Connects to data-controller="parallax"
export default class extends Controller {
  static targets = [];

  calculateBaseY(el) {
    const speed = 1 - parseFloat(el.dataset.speed) / 100;
    return speed * this.power;
  }

  calculateHeroY(_el) {
    return 200;
  }

  connect() {
    this.type = this.element.dataset.parallaxType ?? "base";
    this.power = parseFloat(this.element.dataset.power ?? 4) * 1000;
    this.scrub = this.element.dataset.scrub ?? 1;
    this.elements = this.element.querySelectorAll("[data-speed]");

    this.tl = gsap.timeline({
      scrollTrigger: {
        endTrigger: this.element,
        fastScrollEnd: true,
        invalidateOnRefresh: true,
        preventOverlaps: true,
        scrub: this.type == "base" ? 1 : true,
        trigger: this.type == "base" ? this.element : undefined,
      },
    });

    this.tl.fromTo(
      this.elements,
      { y: 0 },
      {
        ease: "none",
        immediateRender: true,
        y: (i, el) => {
          switch (this.type) {
            case "base":
              return this.calculateBaseY(el);
            case "hero":
              return this.calculateHeroY(el);
          }
        },
      },
    );
  }

  disconnect() {
    this.tl.scrollTrigger.kill();
    this.tl.kill();
  }
}

/* Parallax
  Translate animation on the element or elements linked to the scroll progress.
  Can be used with a single element, but multiple elements can be animated using the same scroll by adding data-speed attribute to any children of the controller element.

  Data attributes on the controller element
  - power: strength modifier for all the parallaxes in the controller, useful to ajust the strength of all the parallaxes without having to change individual values (default: 4)
  - scrub: number of seconds before the scroll catches up. Used to give the parallaxes more life. Use value of true for no delay (default: 1)

  Data attributes on each elements
  - speed: strength of the individual parallax. Must be bigger than 100. Value of 100 is equal to no mouvement (no default value)

  Notes
  - The returned value in the y: (i, el) function is the final y position of the element. The scrollTrigger animates from y value 0 when the viewport is at the top of the controller element, to the final y value when the viewport is at the bottom of the controller element
  - Add a hardcoded css translateY to a parent or children div to reposition the element so that it is in the same position in the page as in figma. Is it useless to change the attributes or the y: (i, el) function to reposition the element.
  - The animations may lag or jump if multiple parallax controllers are used too close too one another. Use a single controller a parent element to fix the issue
*/
