import { Controller } from "@hotwired/stimulus";
import gsap from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);

// Connects to data-controller="animation"
export default class extends Controller {
  static targets = ["bigLeaf", "smallLeaf", "cocoaBean"];

  beanAnimation() {
    this.tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.3,
      yoyo: true,
    });
    this.tl.addLabel("start");

    const beanTl = gsap.timeline({});
    beanTl
      .to(this.cocoaBeanTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: 5 * this.direction })
      .to(this.cocoaBeanTarget, { delay: 0.6, duration: 4, ease: "power1.inOut", rotation: 1 * this.direction })
      .to(this.cocoaBeanTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: 5 * this.direction })
      .to(this.cocoaBeanTarget, { delay: 0.6, duration: 4, ease: "power1.inOut", rotation: 1 * this.direction });

    this.tl.add(beanTl);
  }

  branchAnimation() {
    this.tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.3,
      yoyo: true,
    });
    this.tl.addLabel("start");

    const beanTl = gsap.timeline({});
    beanTl
      .to(this.cocoaBeanTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: 2 * this.direction })
      .to(this.cocoaBeanTarget, { delay: 0.6, duration: 4, ease: "power1.inOut", rotation: -2 * this.direction })
      .to(this.cocoaBeanTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: 2 * this.direction })
      .to(this.cocoaBeanTarget, { delay: 0.6, duration: 4, ease: "power1.inOut", rotation: -2 * this.direction });

    this.tl.add(beanTl);
  }

  connect() {
    this.hasHover = this.element.dataset.hover ?? false;
    this.direction = this.element.dataset.direction ?? 1;
    switch (this.element.dataset.animation) {
      case "leaf-one":
        this.leafAnimationOne();
        break;
      case "leaf-two":
        this.leafAnimationTwo();
        break;
      case "leaf-solo":
        this.leafAnimationSolo();
        break;
      case "bean":
        this.beanAnimation();
        break;
      case "branch":
        this.branchAnimation();
        break;
    }
  }

  disconnect() {
    this.tl.kill();
    if (this.hoverTl) {
      this.hoverTl.kill();
    }
  }

  leafAnimationOne() {

    this.tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.3,
      yoyo: true,
    });
    this.tl.addLabel("start");

    const bigLeafTl = gsap.timeline({});
    bigLeafTl
      .to(this.bigLeafTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: 9 * this.direction })
      .to(this.bigLeafTarget, { delay: 0.7, duration: 4, ease: "power1.inOut", rotation: 2 * this.direction })
      .to(this.bigLeafTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: 10 * this.direction })
      .to(this.bigLeafTarget, { delay: 0.7, duration: 4, ease: "power1.inOut", rotation: 2 * this.direction });

    const smallLeafTl = gsap.timeline({});
    smallLeafTl
      .to(this.smallLeafTarget, {
        delay: 0.3,
        duration: 2.5,
        ease: "power1.inOut",
        rotation: 7 * this.direction,
      })
      .to(this.smallLeafTarget, { delay: 0.3, duration: 4, ease: "power1.inOut", rotation: 0 * this.direction })
      .to(this.smallLeafTarget, { delay: 0.3, duration: 2.5, ease: "power1.inOut", rotation: 8 * this.direction })
      .to(this.smallLeafTarget, { delay: 0.3, duration: 4, ease: "power1.inOut", rotation: 0 * this.direction });

    this.tl.add(bigLeafTl);
    this.tl.add(smallLeafTl, "start");

    this.hoverAnimation = () => {
      this.isAnimating = true;
      this.hoverTl = gsap.timeline({
        repeat: 1,
        repeatDelay: 0.05,
        yoyo: true,
      });
      this.hoverTl
        .to(this.bigLeafTarget, { duration: 0.55, ease: "power1.inOut", rotation: 0 * this.direction })
        .to(this.smallLeafTarget, { duration: 0.55, ease: "power1.inOut", rotation: 8 * this.direction }, "<")
        .then(
          function () {
            this.tl.resume();
            this.isAnimating = false;
          }.bind(this),
        );
    };
  }

  leafAnimationTwo() {

    this.tl = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.3,
      yoyo: true,
    });
    this.tl.addLabel("start");

    const bigLeafTl = gsap.timeline({});
    bigLeafTl
      .to(this.bigLeafTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: 9 * this.direction })
      .to(this.bigLeafTarget, { delay: 0.7, duration: 4, ease: "power1.inOut", rotation: 2 * this.direction })
      .to(this.bigLeafTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: 10 * this.direction })
      .to(this.bigLeafTarget, { delay: 0.7, duration: 4, ease: "power1.inOut", rotation: 2 * this.direction });

    const smallLeafTl = gsap.timeline({});
    smallLeafTl
      .to(this.smallLeafTarget, {
        delay: 0.3,
        duration: 2.5,
        ease: "power1.inOut",
        rotation: 7 * this.direction,
      })
      .to(this.smallLeafTarget, { delay: 0.3, duration: 4, ease: "power1.inOut", rotation: 0 * this.direction })
      .to(this.smallLeafTarget, { delay: 0.3, duration: 2.5, ease: "power1.inOut", rotation: 8 * this.direction })
      .to(this.smallLeafTarget, { delay: 0.3, duration: 4, ease: "power1.inOut", rotation: 0 * this.direction });

    this.tl.add(bigLeafTl);
    this.tl.add(smallLeafTl, "start");

    this.hoverAnimation = () => {
      this.isAnimating = true;
      this.hoverTl = gsap.timeline({
        repeat: 1,
        repeatDelay: 0.05,
        yoyo: true,
      });
      this.hoverTl
        .to(this.bigLeafTarget, { duration: 0.55, ease: "power1.inOut", rotation: 2 * this.direction })
        .to(this.smallLeafTarget, { duration: 0.55, ease: "power1.inOut", rotation: 7 * this.direction * -1 }, "<")
        .then(
          function () {
            this.tl.resume();
            this.isAnimating = false;
          }.bind(this),
        );
    };
  }

  leafAnimationSolo() {
    this.tl = gsap.timeline({
      delay: 0.2,
      repeat: -1,
      repeatDelay: 0.3,
      yoyo: true,
    });
    this.tl.addLabel("start");

    const bigLeafTl = gsap.timeline({});
    bigLeafTl
      .to(this.bigLeafTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: -15 * this.direction })
      .to(this.bigLeafTarget, { delay: 0.6, duration: 4, ease: "power1.inOut", rotation: -8 * this.direction })
      .to(this.bigLeafTarget, { delay: 0.3, duration: 2, ease: "power1.inOut", rotation: -16 * this.direction })
      .to(this.bigLeafTarget, { delay: 0.6, duration: 4, ease: "power1.inOut", rotation: -8 * this.direction });

    this.tl.add(bigLeafTl);

    this.hoverAnimation = () => {
      this.isAnimating = true;
      this.hoverTl = gsap.timeline({
        repeat: 1,
        repeatDelay: 0.05,
        yoyo: true,
      });
      this.hoverTl
        .to(this.bigLeafTarget, { duration: 0.55, ease: "power1.inOut", rotation: -18 * this.direction })
        .then(
          function () {
            this.tl.resume();
            this.isAnimating = false;
          }.bind(this),
        );
    };
  }

  onMouseOver() {
    if (this.hasHover && !this.isAnimating) {
      this.tl.pause();
      this.hoverAnimation();
    }
  }
}
